import React from "react"
import { graphql, type PageProps } from "gatsby"
import { Seo } from "../gatsby-components"
import { GridListTemplate } from "../templates"
import {
  sanityProjectsToGridListItems,
  insertBlackLinks,
} from "../gatsby-helper"
import { layout } from "../temp-content/layout"
import { GatsbySanityProject } from "src/types"

export const ProjektePage = ({ data }: ProjektePageProps) => {
  const gatsbySanityProjects = data.allSanityProject.nodes
  const gridListItems = sanityProjectsToGridListItems(gatsbySanityProjects)
  const items = insertBlackLinks(gridListItems)
  return (
    <>
      <Seo title="Projekte" />
      <GridListTemplate {...{ layout, items }} />
    </>
  )
}

export type ProjektePageProps = PageProps<{
  allSanityProject: {
    nodes: GatsbySanityProject[]
  }
}>

export const query = graphql`
  query ProjektePageQuery {
    allSanityProject(sort: { fields: orderRank, order: ASC }) {
      nodes {
        id
        name
        scope
        titleImage {
          asset {
            gatsbyImageData(backgroundColor: "#000", placeholder: NONE)
          }
        }
        slug {
          current
        }
      }
    }
  }
`

export default ProjektePage
